<template>
	<div class="block partner">
		<div class="wrap">
    		<d-title text="合作伙伴" color="#333" fontsize="36px"></d-title>
			<div class="con">
				      <div class="item" @click="goto()" v-for="(item,index) in list" :key="item.id">
                <div class="img-box">
                  <img :title="item.title" :src="item.image_path"></div>
                  <div class="title">{{item.title}}</div>
                </div>
                <!-- <div class="item" @click="goto()">
                  <div class="img-box"><img title="安泰科技" :src="require('../../../assets/partner/001.jpg')"></div>
                  <div class="title">安泰科技</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="恒新增材制造研究中心" :src="require('../../../assets/partner/002.jpg')"></div>
                  <div class="title">恒新增材制造研究中心</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="华科三维" :src="require('../../../assets/partner/003.jpg')"></div>
                  <div class="title">华科三维</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="西北有色金属研究院" :src="require('../../../assets/partner/004.jpg')"></div>
                  <div class="title">西北有色金属研究院</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="西安铂力特激光成形技术有限公司" :src="require('../../../assets/partner/005.jpg')"></div>
                  <div class="title">西安铂力特激光成形技术有限公司</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="宁夏共享模具有限公司" :src="require('../../../assets/partner/006.jpg')"></div>
                  <div class="title">宁夏共享模具有限公司</div>
                </div>
                <div class="item" @click="goto()">
                  <div class="img-box"><img title="武汉华科三维科技有限公司" :src="require('../../../assets/partner/007.jpg')"></div>
                  <div class="title">武汉华科三维科技有限公司</div>
                </div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import DTitle from '@/components/DTitle.vue';
import {useRouter} from 'vue-router';
import service from '../../../service.js';

export default {
	name: '',
	components:{DTitle},
	props:{

	},
	setup(){
    const list = ref([]);

    service({url:"/api/links",method: 'get',params:""})
    .then(res=>{
      list.value = res.retdata.list;
    });

 		return {list}
	}
}
</script>

<style scoped>
.block{padding:80px 0; background: #e5e5e5;}
.block .wrap{max-width: 1046px; margin:auto;}

/*.con{padding:7px; font-size:20px; line-height: 1.6; color:#333; margin-top:40px; display: flex; justify-content: center; flex-wrap: wrap;}
.item{width:200px; margin:10px 10px;}
.item .img-box{width:200px; height:200px;}
.item .img-box>img{width:200px; height:200px; object-fit: cover;}
.item .title{font-size:16px; color:#333; text-align: center; margin-top:5px; font-weight: bold;}*/
.partner .con{padding:7px; font-size:20px; line-height: 1.6; color:#333; margin-top:40px; display:flex; flex-wrap: wrap; justify-content: center;}
.partner .item{margin:10px 10px; display:inline-block; }
.partner .item .img-box{width: 100%; height:70px; text-align:center; display:inline-block; border:1px solid #ccc; box-sizing: content-box;}
.partner .item .img-box>img{width:auto; height:70px; }
.partner .item .title{display:none; font-size:14px; color:#333; text-align: center; margin-top:5px; font-weight: bold;}

@media screen and (max-width:800px) {
.block{padding:50px 0 20px 0;}
.partner .con{justify-content: center;}
.partner .item .img-box{width: 100%; height:42px;}
.partner .item .img-box>img{width:auto; height:42px; }
.partner .item .title{display:none; font-size:12px; margin-top:2px; }
}
</style>