<template>
  <div class="swiperbox" :class="type">
      <swiper
        ref="sw"
        slot="pagination"
        :auto-update="true"
        :slides-per-view="1"
        :space-between="50"
        :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
        :loop="list.length>1"
        :autoplay="{delay:5000}"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for='(item,index) in list' :key='index'  class="swiper-slide" >
          <div class="wrap">
            <img class="img" :src="item.image_path" > 
            <div class="fixed-text">
              <div class="wrap">
                <d-title :text="item.title" color="#fff" :fontsize="fontsize" :hideLine="true"></d-title>
                <div class="sub-title" v-if="item.description!=''">{{item.description}}</div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      
   </div>
</template>

<script>
  // import Swiper core and required components
  import SwiperCore, { Navigation, Pagination, Autoplay,A11y } from 'swiper';
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import DTitle from '@/components/DTitle.vue'

  import {useGetDevice} from '@/use/diy-use.js';

  import 'swiper/swiper-bundle.css';

  import service from '../service.js';


  // Import Swiper styles
  // import 'swiper/swiper.scss';
  // import 'swiper/components/navigation/navigation.scss';
  // import 'swiper/components/pagination/pagination.scss';
  // import 'swiper/components/scrollbar/scrollbar.scss';

  // install Swiper components
  SwiperCore.use([Navigation, Pagination, Autoplay,A11y]);
  import { ref ,computed} from 'vue'
  // import {useStore} from 'vuex'
  // Import Swiper styles
  export default {
    props:{
      // list:{type:Array,default:[]},
      type:{type:String,default:'exhibitor'}
    },
    components: {
      Swiper,
      SwiperSlide,
      DTitle
    },
    setup(){
      const list = ref([]);
      // const store = useStore();

      // if(store.state.banner.length!==0){
      //   list.value=store.state.banner;
      // }else{
      //   store.dispatch("getPoster",{type:'2'}).then(res=>{
      //     console.log(res);
      //     list.value = res;
      //   })
      // }
      const fontsize = ref(null);
      const deviceType = useGetDevice();
      if(deviceType==='pc'){
        fontsize.value="45px"
      }else{
        fontsize.value="20px"
      }

      service({url:"/api/banner",method: 'get',params:{action:"list"}})
      .then(res=>{
        list.value = res.retdata.list;
      });

      // list.value = [{image_path:require('../assets/swiper01.jpg'),href:'###',title:'广东增减材科技  以核心技术缔造优质设备',description:"源于国创  为国争光"}
      // ,{image_path:require('../assets/swiper02.jpg'),href:'###',title:'未来工厂的建设者  创新技术的推动者',description:""}]

      return {
        list,
        fontsize
      }
    }
  };
</script>

<style>
.swiperbox{width:100%; position: relative;}
.swiperbox .swiper-container{width:100%; margin:auto; height:500px;}
.swiperbox .swiper-container .swiper-slide{width:100%; height:500px;}
.swiperbox .swiper-container .swiper-slide>.wrap{position: relative;}
.swiperbox .swiper-container .swiper-slide>.wrap,.swiperbox .swiper-container .swiper-slide img
{display:block; width:100%;margin:auto; height:500px; object-fit: cover;}
.swiperbox .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet
{width:6px; height:6px; border-radius: 7px; background: #fff; opacity:1; transition-duration: 300ms;}

.exhibitor.swiperbox .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active
{width:22px; background:#2b82e0;}
.builder.swiperbox .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active
{width:22px; background:#ff4d4f;}

.swiperbox .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  opacity: 1!important; 
  cursor: pointer; 
  pointer-events: auto!important;
  color:#217dcf!important;
}

.builder .swiper-button-prev.swiper-button-disabled, .builder .swiper-button-next.swiper-button-disabled{color:#ff4d4f!important;}


.fixed-text{position: absolute; z-index:99; top:0; bottom:0; left:0; right:0; display:flex; background: rgba(0,0,0,.4); user-select: none; pointer-events:none;}
.fixed-text .wrap{margin:auto;}
.sub-title{font-size:20px; line-height:1.6; color:#fff; text-align:center;}

@media screen and (max-width: 800px) {
.swiperbox .swiper-container{height:280px;}
.swiperbox .swiper-container .swiper-slide{ height:280px;}
.swiperbox .swiper-container .swiper-slide>.wrap,.swiperbox .swiper-container .swiper-slide img
{height:280px; }
}
</style>

