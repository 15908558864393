<template>
  <div class="handlebox">
    <div class="mask">
      
      <LinkOutlined />
      <div class="lnk-text">了解更多</div>
      <slot></slot>
    </div>
    <div class="imgbox">
      <img :src="src">
    </div>
  </div>
</template>

<script>
import { EyeOutlined,LinkOutlined } from '@ant-design/icons-vue';

export default {
  name: 'handlebox',
  components:{EyeOutlined,LinkOutlined},
  props: {
    src: String
  },
  setup(props,{emit}){
  	const back = ()=>{
  		emit('back')
  	}
  	return {
  		back
  	}
  }
}
</script>

<style scoped>
.handlebox{position: absolute; top:0;bottom:0;right:0;left:0; cursor:pointer;}
.handlebox .mask{z-index:9;position: absolute; top:0;bottom:0;right:0;left:0; background:rgba(0,0,0,0); display: flex; justify-content: center; align-items: center; flex-direction: column; color:#fff; font-size:22px; transition-duration: 300ms;}
.handlebox .mask>span,.handlebox .mask>a,.handlebox .mask>.lnk-text{transform: scale(.5); opacity:0; transition-duration: 300ms; margin:0 5px;}
.handlebox .imgbox{z-index:8;position: absolute; top:0;bottom:0;right:0;left:0; display: flex;}
.handlebox .imgbox>img{margin:auto; max-width:100%; max-height: 100%;}
.handlebox:hover .mask{background:rgba(0,0,0,0.4);}
.handlebox:hover .mask span,.handlebox:hover .mask a,.handlebox:hover .mask .lnk-text{transform: scale(1); opacity: 1}

.handlebox .mask .lnk-text{font-size:14px; margin-top:5px;}

</style>