<template>
	<div class="block">
		<div class="wrap">
    		<d-title text="应用案例" color="#333" fontsize="36px"></d-title>
    		<!-- <div class="sub-title">在这里展示你的项目，功能或客户</div> -->
			<div class="con">
				<div class="item" v-for="(item,index) in list1" @click="goto(item.id)">
					<div class="img-box">
						<img :src="item.image_path">
					</div>
					<div class="text-box">
						<div class="center">
							<h2>{{item.name}}</h2>
							<h3>{{item.description}}</h3>
							<!-- <ul>
								<li v-for="(subitem,subindex) in item.list">{{subitem}}</li>
							</ul> -->
						</div>
					</div>
					<!-- <div class="title">{{item.title}}</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref,onMounted,watch,computed} from 'vue'
import DTitle from '@/components/DTitle.vue'
import {useRouter} from 'vue-router'
import service from '../../../service.js';


export default {
	name: 'case',
	components:{DTitle},
	props:{

	},
	setup(){
		const list1 = ref([]);

		let getCaseList = async function(){
	      const res =(await service({url:"/api/case?action=recommend",method: 'get'})).retdata.list; 	
	      	list1.value = res;
	      	return list1;
	    }
	    getCaseList()


		const router = useRouter();
		const goto = (id)=>{
            router.push({name:'case',params:{id:id}});
		}

 		return {list1,goto}
	}
}
</script>

<style scoped>
.block{padding:80px 0;}
.block .wrap{width:1260px; margin:auto;}

.sub-title{font-size:20px; line-height:1.6; color:#3167db; text-align:center;}

.con{margin-top:30px; padding:7px; font-size:20px; line-height: 1.6; color:#333; display:flex; justify-content: center; flex-wrap: wrap;}

.item{flex:0 0 300px; width:300px; height:222px; position: relative; margin:0 5px 10px 5px; transition-duration: 500ms; overflow: hidden;}
.item .img-box{width:300px; height:222px; position: absolute; top:0; left:0;}
.item .img-box img{width:300px; height:222px; object-fit: cover;}

.item .text-box{display:flex; height:222px; padding:10px 12px 10px 12px; position: absolute; top:0; bottom:0; left:0; right:0; background: rgba(0,0,0,0); color:#fff; cursor:pointer; user-select:none; transition-duration: 300ms; transform: translateY(-30px);}
.item .text-box .center{margin:auto;}
.item .text-box h2{font-size:20px; font-weight: bold; color:#fff; transition-duration: 500ms; opacity:0; transform: translateY(5px);}
.item .text-box h3{font-size:15px; font-weight: 500; color:#fff; margin:5px 0 5px 0; transition-duration: 500ms; opacity:0; transform: translateY(5px);}
.item .text-box ul{/*list-style-type: disc; padding-left:18px; */padding-right:5px; font-size:14px; line-height: 1.6; transition-duration: 500ms; opacity:0; transform: translateY(5px);}

/*.item:hover{box-shadow: 1px 1px 6px rgba(0,0,0,.6)}
.item:hover .text-box{ background: rgba(0,0,0,.4); transform: translateY(0px);}
.item:hover .text-box h2{opacity:1; transform: translateY(0);}
.item:hover .text-box h3{opacity:1; transform: translateY(0);}
.item:hover .text-box ul{opacity:1; transform: translateY(0);}*/
.item{box-shadow: 1px 1px 6px rgba(0,0,0,.6)}
.item .text-box{ background: rgba(0,0,0,.4); transform: translateY(0px);}
.item .text-box h2{opacity:1; transform: translateY(0);}
.item .text-box h3{opacity:1; transform: translateY(0);}
.item .text-box ul{opacity:1; transform: translateY(0);}


.item .title{position: absolute; bottom:0; left:0; right:0; text-indent:12px; font-size:15px; height:30px; line-height:30px; color:#fff; 
	transition-duration: 500ms; background: rgba(0,0,0,.4); opacity:1; transform: translateY(0);}
.item:hover .title{background: rgba(0,0,0,0); opacity:0; transform: translateY(30px);}

@media screen and (max-width: 800px) {
	.block{padding:50px 0 0px 0;}

	.block .wrap{width:auto;}
	.item{flex:0 0 390px; width:390px; height:240px;}
	.item .img-box{width:390px; height:240px;}
	.item .img-box img{width:390px; height:240px;}
	.item .text-box{height:240px;}
	.item{box-shadow: 1px 1px 6px rgba(0,0,0,.6)}
	.item .text-box{ background: rgba(0,0,0,.4); transform: translateY(0px);}
	.item .text-box h2{opacity:1; transform: translateY(0);}
	.item .text-box h3{opacity:1; transform: translateY(0);}
	.item .text-box ul{opacity:1; transform: translateY(0);}
	.item .title{background: rgba(0,0,0,0); opacity:0; transform: translateY(30px);}

	.item .text-box h2{font-size:26px;}
	.item .text-box h3{font-size:16px;}
	.item .text-box ul{font-size:15px;}
}

</style>