<template>
  <div class="content">
  	<d-header @scrollTo="scrollToPixel" :activeIdx="activeIdx" id="dheader"></d-header>
   <banner id="banner"></banner>
  	<Intro id="intro"></Intro>
  	<Product id="product"></Product>
  	<Case id="case"></Case>
  	<Partner id="partner"></Partner>
  	<News id="news"></News>
  	<Contact id="contact"></Contact>
    <back-top></back-top>
  	<d-footer ></d-footer>
  </div>
</template>

<script>
import { ref,reactive,toRefs,onMounted,onUnmounted, watch,computed} from 'vue'
// import {useStore} from 'vuex'
// import {useRouter, useRoute} from 'vue-router'
import banner  from '@/components/banner.vue'

import backTop  from '@/components/backTop.vue'

import DTitle from '@/components/DTitle.vue'
import DHeader from './components/DHeader.vue'
import DFooter from './components/DFooter.vue'
import Intro from './components/Intro.vue'
import Product from './components/Product.vue'
import Case from './components/Case.vue'
import Partner from './components/Partner.vue'
import News from './components/News.vue'
import Contact from './components/Contact.vue'




export default {
   name: 'index',
   components:{banner,DTitle,backTop,DHeader,DFooter,DTitle,Intro,Product,Case,Partner,News,Contact},
   setup(){
      const activeIdx = ref(1);

      document.title="粤增减材|五轴增减材一体机|增减材复合制造技术|金属3D打印|科研金属3D打印机|工业金属3D打印机|3D打印教育培训|广东增减材科技有限公司";
      
      onMounted(()=>{
         const dheader = document.getElementsByClassName("d-header")[0];
         const handleScroll = ()=>{
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            let winWidth = document.body.clientWidth;
            // console.log(scrollTop);
            if(scrollTop>200){
                if(winWidth>800){
                  dheader.style.top='-60px';
                }else{
                  dheader.style.top='-42px';
                }
            }else{
               dheader.style.top='0px';
            }

            if(scrollTop<308){activeIdx.value=1;}
            if(scrollTop>308){activeIdx.value=2;}
            if(winWidth>800){
              if(scrollTop>777){activeIdx.value=3;}
              if(scrollTop>1444){activeIdx.value=4;}
              if(scrollTop>1777){activeIdx.value=5;}
              if(scrollTop>2555){activeIdx.value=6;}
              if(scrollTop>3100){activeIdx.value=7;}
            }else{
              if(scrollTop>958){activeIdx.value=3;}
              if(scrollTop>2600){activeIdx.value=4;}
              if(scrollTop>3785){activeIdx.value=5;}
              if(scrollTop>4216){activeIdx.value=6;}
              if(scrollTop>4700){activeIdx.value=7;}
            }

         }
         window.addEventListener("scroll",handleScroll); 
         // document.removeEventListener('scroll', handleScroll);
      })
    
      

      const scrollToPixel = (id,idx=1)=>{
         activeIdx.value=idx;
         // console.log(id);
         let elemTop = document.getElementById(id).offsetTop;
         // console.log(elemTop);
         let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

         let speed = (elemTop-scrollTop)/25;
         let dir = elemTop>scrollTop?'down':'up';

         let sti = setInterval(()=>{
            if(dir==='down'&&scrollTop+speed<elemTop-46){
               scrollTop+=speed;
               document.documentElement.scrollTop = scrollTop;
               document.body.scrollTop = scrollTop;
            }else if(dir==='down'){
               clearInterval(sti);
               document.documentElement.scrollTop=elemTop-46;
               document.body.scrollTop=elemTop-46;
            }

            if(dir==='up'&&scrollTop+speed>elemTop-46){
               scrollTop+=speed;
               document.documentElement.scrollTop = scrollTop;
               document.body.scrollTop = scrollTop;
            }else if(dir==='up'){
               clearInterval(sti);
               // console.log('clearInterval');
               document.documentElement.scrollTop=elemTop-46;
               document.body.scrollTop=elemTop-46;
            }
         },1000/60);
      }

   
      return {
         scrollToPixel,
         activeIdx
    }
  }
}
</script>

<style scoped>
.content{padding-top:116px;}
@media screen and (max-width: 800px) {
.content{padding-top:90px;}
}
</style>
