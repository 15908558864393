<template>
	<div class="block">
		<div class="wrap">
    		<d-title text="新闻资讯" color="#333" fontsize="36px"></d-title>
			<div class="con">
				<ul class="news-list2">
					<li v-for="(item,index) in list" :key="item.id" @click="goto(item.id)"><div class="a">
				        <div class="img-box">
		           			<img :src="item.image_path" />
				        </div>
				        <div class="right">
				          <h2 class="ell">{{item.title}}</h2>
				          <h5>{{item.resume}}</h5>
				          <div><span>{{item.created_time}}</span><span v-if="item.number>0">{{item.number}}</span></div>
				        </div>
				      </div>
				  	</li>
					<!-- <li @click="goto('0')"><div class="a">
				        <div class="img-box">
		           			<img :src="require('../../../assets/news/005.jpg')" />
				        </div>
				        <div class="right">
				          <h2 class="ell">首期增材制造技术职业教育师资培训班扬帆起航</h2>
				          <h5>增材制造技术应用与创新设计师资培训班开班仪式在三水国创佛山分中心隆重召开。</h5>
				          <div><span>2021-01-20</span><span>300</span></div>
				        </div>
				      </div>
				  	</li>
				    <li @click="goto('1')"><div class="a">
				        <div class="img-box">
	               			<img :src="require('../../../assets/news/001.jpg')" />
				        </div>
				        <div class="right">
				          <h2 class="ell">增材制造重塑“快速原型”或将彻底改变制造业格局</h2>
				          <h5>快速原型向增材制造的发展以及相关创新正在扩展工业模式，其范围已超出人们的想象。如今，汽车、航空航天、医疗、精密模具和制造业等工业领域，对增材制造及其应用的需求越来越大。</h5>
				          <div><span>2021-01-20</span><span>300</span></div>
				        </div>
				      </div>
				  	</li>
				    <li @click="goto('2')"><div class="a">
				        <div class="img-box"><img :src="require('../../../assets/news/003.jpg')" /></div>
				        <div class="right">
				          <h2 class="ell">增材制造应用篇——工业装备制造</h2>
				          <h5>在工业装备制造领域，增材制造技术能较好地满足短时耗、高精度、复杂构件的需求，随着工业装备制造精细化、定制化要求的不断提高，增材制造技术将在该领域发挥重要作用。</h5>
				          <div><span>2021-01-20</span><span>300</span></div>
				        </div>
				      </div>
				    </li> -->
				</ul>
			</div>

			<router-link to="/news" class="get-more">更多新闻</router-link>

		</div>
	</div>
</template>

<script>
import { ref } from 'vue'
import DTitle from '@/components/DTitle.vue';
import {useRouter} from 'vue-router';
import service from '../../../service.js';



export default {
	name: '',
	components:{DTitle},
	props:{

	},
	setup(){
    	const list = ref([]);

 		const router = useRouter();
		const goto = (id)=>{
            router.push({path:'/news/'+id});
		}

		service({url:"/api/news",method: 'get',params:{action:"list"}})
	    .then(res=>{
	      list.value = res.retdata.list.slice(0,5);
	    });
 		return {goto,list}
	}
}
</script>

<style scoped>
.block{padding:80px 0;}
.block .wrap{max-width: 1056px; margin:auto;}

.con{padding:15px; font-size:20px; line-height: 1.6; color:#333;}

.news-list2{max-width:1100px; margin:20px auto 20px auto; overflow:hidden;}
.news-list2 li{margin-bottom:10px;}
.news-list2 li>.a{display:block; background:#f6f6f6; display:flex; transition-duration: 300ms; cursor:pointer;}
/*.news-list2 li>.a:hover{background:#eee;}*/
.news-list2 li>.a .img-box{flex:0 0 250px; width:250px; height:140px; overflow: hidden;}
.news-list2 li>.a .img-box>img{width:250px; height:140px; object-fit: cover; transition-duration: 300ms;}
.news-list2 li>.a:hover .img-box>img{transform:scale(1.05);}
.news-list2 li>.a .right{max-width:100%; padding:10px 10px; display:flex; flex-direction: column; justify-content: center;}
.news-list2 li>.a .right h2{padding:0px 0; font-size:26px; }
.news-list2 li>.a .right h5{padding:10px 0; font-size:14px; line-height:22px; font-weight: normal;}
.news-list2 li>.a .right div{line-height:20px; height:20px;}
.news-list2 li>.a .right div span{font-size:14px; color:#999; margin-right:10px;}
.news-list2 li>.a .right div span:before{content:''; display:inline-block; width:16px; height:16px; position: relative; margin-right:2px;}
.news-list2 li>.a .right div span:nth-of-type(1):before{top:2px; width:16px; height:16px; background:url(../../../assets/icon-time.png) no-repeat; background-size:100% 100%;}
.news-list2 li>.a .right div span:nth-of-type(2):before{top:4px; width:20px; height:20px; background:url(../../../assets/icon-eyes.png) no-repeat; background-size:100% 100%;}
.news-list2 li:hover{box-shadow:0 0 14px 0 #ccc}

.get-more{display: block; width: 200px;height: 40px;margin: 5px auto 30px auto;line-height: 40px;text-align: center;font-size: 16px;color: #333;border: 1px solid #ddd;cursor: pointer;}

@media screen and (max-width: 800px) {
.block{padding:50px 0 0px 0;}	
.news-list2{margin:20px auto 0px auto; }
.news-list2 li>.a .img-box{flex:0 0 100px; width:100px; height:70px; overflow: hidden;}
.news-list2 li>.a .img-box>img{width:100px; height:70px; object-fit: cover; transition-duration: 300ms;}
.news-list2 li>.a:hover .img-box>img{transform:scale(1.05);}

.news-list2 li>.a .right h2{max-width:calc( 100vw - 150px );  font-size:18px; }
.news-list2 li>.a .right h5{display:none;}

}
</style>