<template>
	<div class="block product-box">
		<div class="wrap">
    		<d-title text="核心装备" color="#333" fontsize="36px"></d-title>
			<div class="con">
				<div class="item" @click="goto(item.id)" v-for="(item,index) in list">
					<handle-box :src="item.image_path"></handle-box>
					<h3>{{item.name}}</h3>
					<ul>
						<li>{{item.description}}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref} from 'vue';

import DTitle from '@/components/DTitle.vue'
import handleBox from '@/components/handleBox.vue'
import {useRouter} from 'vue-router'
import service from '../../../service.js';

export default {
	name: 'product',
	components:{DTitle,handleBox},
	props:{

	},
	setup(){
        const router = useRouter();

        const list = ref([]);
		let getList = async function(){
	      const res =(await service({url:"/api/product?action=recommend",method: 'get'})).retdata.list; 
	      	console.log(res);	
	      	list.value = res;
	      	return list;
	    }
	    getList();

		const goto = (id)=>{
            router.push({name:'product',params:{id:id}});
		}
 		return {list,goto}
	}
}
</script>

<style scoped>
.block{padding:80px 0; background: #e5e5e5;}
.block .wrap{width: 1260px; margin:auto;}

.con{margin-top:20px; display:flex; flex-wrap: wrap; justify-content: center; padding:7px; font-size:20px; line-height: 1.6; color:#333;}

.item{flex:0 0 300px; margin:0 5px 10px 5px;}
.item .handlebox{width:300px; height:222px; position: relative; border:1px solid #ccc; background:#fff;}
.item .handlebox img{object-fit: cover;}
.item h3{font-size:18px; font-weight: bold; color:#333; margin-top:5px; cursor:pointer;}
.item li{font-size:16px;}

@media screen and (max-width:800px) {
.block{padding:50px 0 0px 0; }
.block .wrap{width: auto; margin:auto;}
.item{flex:0 0 390px; margin:0 0px 10px 0px;}
.item .handlebox{width:390px; height:250px;}
}
</style>

<style type="text/css">
.product-box .item .handlebox img{width:300px; height:222px; object-fit: cover;}
@media screen and (max-width:800px) {
	.product-box .item .handlebox img{width:390px; height:250px; object-fit: cover;}
}
</style>