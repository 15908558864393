<template>
	<div class="block">
		<div class="wrap">
    		<d-title text="联系我们" color="#fff" fontsize="36px"></d-title>
    		<div class="sub-title">如有疑问请随时致电或者留下您的个人信息以了解更详细的服务项目</div>
			<div class="con">
				<form class="form-box">
					<div class="input-box">
						<input type="text" name="contact" v-model="form.contact" placeholder="请填写您的姓名" />
						<!-- <a-input addon-before="姓名" v-model:value="form.name" /> -->
					</div>
					<div class="input-box"><input type="text" name="mobile" v-model="form.mobile" placeholder="请填写您的手机号"/></div>

					<div class="input-box"><input type="text" name="email" v-model="form.email" placeholder="请填写您的邮箱"/></div>
					<div class="textarea-box"><textarea name="content" v-model="form.content" placeholder="请输入您要咨询的内容"></textarea></div>
					<a-button type="primary" size="large" @click="submit">提 交</a-button>
				</form>
				<div class="tips-box">
					<div><a href="https://j.map.baidu.com/13/CNJ" target="_bland"><EnvironmentOutlined /> 佛山市三水区乐南路4号</a></div>
					<div><a href="tel:18666555628"><MobileOutlined /> 18666555628 周先生</a></div>
					<!-- <div><MailOutlined /> info@xxxxx.com</div> -->
					<div>
						<img src="http://www.yuezengjian.com/img/wxcode.jpg" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DTitle from '@/components/DTitle.vue'
import { EnvironmentOutlined,MobileOutlined,MailOutlined} from '@ant-design/icons-vue'
import { ref,reactive,toRefs,toRaw, onMounted,onUnmounted, watch, computed} from 'vue'
import { message } from 'ant-design-vue';
import service from '../../../service.js';



export default {
	name: 'contact',
	components:{DTitle,EnvironmentOutlined,MobileOutlined,MailOutlined},
	props:{

	},
	setup(){
		const form = reactive({
			contact:'',
			mobile:"",
			email:'',
			content:''
		})

		const submit = ()=>{
			if(form.contact===''){
            	message.error({content:"请填写您的姓名！"});
            	return false;
			}
			if(form.mobile===''){
            	message.error({content:"请填写您的姓名！"});
            	return false;
			}
			if(form.email===''){
            	message.error({content:"请填写您的邮箱！"});
            	return false;
			}
			if(form.content===''){
            	message.error({content:"请输入要咨询的内容！"});
            	return false;
			}

			if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(form.mobile)){
				message.error({content:"手机号输入有误，请检查！"});
				return false;
			}
			if (!/^(\w+)(\.\w+)*@(\w+)(\.\w+)*.(\w+)$/i.test(form.email)){
				message.error({content:"邮箱格式输入有误，请检查！"});
				return false;
			}

			service({url:"/api/message/save",method: 'post',data:toRaw(form)})
		    .then(res=>{
		      console.log(res);
		      if(res.errcode===0){
		      	message.success({content:res.errmsg});
		      	form.contact="";
		      	form.mobile="";
		      	form.email="";
		      	form.content="";
		      }else{
				message.error({content:res.errmsg});
		      }
		    });

		}

 		return {form,submit}
	}
}
</script>

<style scoped>
.block{
	padding:90px 0; background: url(../../../assets/190.jpg) no-repeat; background-size: 100% auto; 
	position: relative;
	/*background-repeat: no-repeat;
    background-size: 1886.04px 1060.9px;
    background-color: transparent;
    background-position: -46.0222px 97.9359px;
    background-image: url(../../../assets/149.jpg);
    background-attachment: fixed;*/
}
.block:before {
    content: "";
    display: block!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.39);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.block .wrap{width: 1056px; margin:auto;}

.sub-title{position: relative; z-index:9; font-size:20px; line-height:1.6; color:#fff; text-align:center;}
.con{position: relative; z-index:999; padding:7px; font-size:20px; line-height: 1.6; color:#fff; display:flex; flex-wrap: wrap; justify-content: center; margin-top:30px;}
.con .form-box{flex:0 0 390px; width:390px; padding-bottom:30px;}
.con .form-box .input-box,
.con .form-box .textarea-box{width:390px; padding:6px 10px; background:#fff; border-radius:5px; margin-bottom:10px;color:#333; font-size: 0;}
.con .form-box input{width:370px; height:30px; font-size:16px; padding:6px 5px; border:none; outline:none;}
.con .form-box textarea{width:370px; height:90px; font-size:16px; outline: none; border:none; }

.con .tips-box{flex:0 0 200px; width:200px; font-size:16px; margin-left:25px;}
.con .tips-box a{color:#fff; text-decoration: underline;}
.con .tips-box>div{line-height:27px;}
.con .tips-box>div>span{margin-right:5px;}
.con .tips-box>div img{width:194px; margin-top:10px;}

@media screen and (max-width:800px) {
	.block{
		padding:50px 0 30px 0; background: url(../../../assets/190.jpg) no-repeat; background-size: 100% 100%; 
	}
	.block .wrap{width: auto; margin:auto;}
	.sub-title{font-size:16px; padding:0 20px;}
	.con .form-box{text-align: center;}
	.con .form-box input{height:20px; font-size:14px; padding:5px 5px;}
	.con .form-box textarea{height:60px;}
	.block .wrap button{display:block; width:390px; border-radius: 5px;}
}
</style>