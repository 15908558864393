<template>
	<div class="block">
		<div class="wrap">
    		<d-title text="企业概况" color="#333" fontsize="36px"></d-title>
			<div class="con">
				<p>公司由中国工程院卢秉恒院士担任首席科学家，国家级特聘专家、2020年广东省金属增材制造基础研究重大项目课题负责人之一张丽娟博士担任总工程师，核心研发力量来自国家增材制造创新中心增减材一体机项目组，通过佛山市科技创新团队项目引进落地产业化，是国内最早实现增减材一体化系统解决方案的制造厂商之一。</p>
				<p>项目组专注于增减材复合制造装备、关键部件、工艺、软件等方面研发和制造，成功突破国外技术封锁，已成为国内增减材一体机销售最多的创新创业团队。<br><a @click="goto('/about')">了解更多></a></p>
				<!-- <div class="intro-img"><img :src="require('../../../assets/intro.jpg')" /></div> -->
				<div class="flexbox">
					<div class="item" v-for="(item,index) in list" @click="goto(item.id)">
						<div class="img-box">
							<img :src="item.image">
						</div>
						<div class="text-box">
							<div class="center">
								<h2>{{item.title}}</h2>
								<h3>{{item.subTitle}}</h3>
								<ul>
									<li v-for="(subitem,subindex) in item.list">{{subitem}}</li>
								</ul>
							</div>
						</div>
						<!-- <div class="title">{{item.title}}</div> -->
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import DTitle from '@/components/DTitle.vue'
import {useRouter} from 'vue-router'

export default {
	name: '',
	components:{DTitle},
	props:{

	},
	setup(){
		const list = [
		{
			image:require('../../../assets/about/002.jpg'),
			title:"科研团队",
			subTitle:"两大创新中心，打造增减材制造一体化最尖端技术",
			id:'/about/ab2',
			list:[
				"2名中国工程院院士",
				"8名国家级特聘专家",
				"230名博硕士技术人员",
				"30多项申请专利"
			]
		},{
			image:require('../../../assets/about/003.jpg'),
			title:"技术介绍",
			subTitle:"硬核科技 中国底气",
			id:'/about/ab3',
			list:[
				
			]
		},{
			image:require('../../../assets/about/004.jpg'),
			title:"服务范围",
			subTitle:"",
			id:'/about/ab4',
			list:[
				"设备研发与制造",
				"激光增材制造",
				"教育培训",
				"光学分析",
				"软件开发"
			]
		}]
		const router = useRouter();
		const goto = (path)=>{
            router.push({path:path});
		}

 		return {list,goto}
	}
}
</script>

<style scoped>
.block{padding:80px 0;}
.block .wrap{max-width: 1160px; margin:auto;}

.con{padding:7px; font-size:20px; line-height: 1.6; color:#333;  margin-top: 10px;}
.con p{text-indent:2em; font-size:16px; line-height: 1.6; color:#666;}
.con .intro-img{padding:10px 7px 0 7px;}
.con .intro-img>img{width:100%;}

.flexbox{margin-top:20px; font-size:20px; line-height: 1.6; color:#333; display:flex; justify-content: center; flex-wrap: wrap;}
.item{flex:0 0 370px; width:370px; height:228px; position: relative; margin:0 5px 10px 5px; transition-duration: 500ms; overflow: hidden;}
.item .img-box{width:370px; height:228px; position: absolute; top:0; left:0;}
.item .img-box img{width:370px; height:228px; object-fit: cover;}

.item .text-box{display:flex; height:228px; padding:10px 12px 10px 12px; position: absolute; top:0; bottom:0; left:0; right:0; background: rgba(0,0,0,0); color:#fff; cursor:pointer; user-select:none; transition-duration: 300ms; transform: translateY(-30px);}
.item .text-box .center{margin:auto;}
.item .text-box h2{font-size:22px; font-weight: bold; color:#fff; transition-duration: 500ms; opacity:0; transform: translateY(5px);}
.item .text-box h3{font-size:15px; font-weight: 500; color:#fff; margin:5px 0 5px 0; transition-duration: 500ms; opacity:0; transform: translateY(5px);}
.item .text-box ul{padding-left:0px; padding-right:5px; font-size:14px; line-height: 1.6; transition-duration: 500ms; opacity:0; transform: translateY(5px);}

.item{box-shadow: 1px 1px 6px rgba(0,0,0,.6)}
.item .text-box{ background: rgba(0,0,0,.5); transform: translateY(0px);}
.item .text-box h2{opacity:1; transform: translateY(0);}
.item .text-box h3{opacity:1; transform: translateY(0);}
.item .text-box ul{opacity:1; transform: translateY(0);}


@media screen and (max-width: 800px) {
.block{padding:50px 0 0px 0;}

.con{padding:15px;}
.item{flex:0 0 390px; width:390px; height:240px; }
.item .img-box{width:390px; height:240px; }
.item .img-box img{width:390px; height:240px;}
.item .text-box{height:240px;}

.item .text-box h2{font-size:26px;}
.item .text-box h3{font-size:16px;}
.item .text-box ul{font-size:15px;}
}

</style>