<template>
	<div class="d-header">
		<div class="wrapper">
			<div class="text-logo"><img src="@/assets/logo-text.png"></div>
			<ul class="nav">
				<span>
					<li @click="scrollTo($event,'dheader',1)" :class="{active:activeIdx===1}">首页</li>
					<li @click="scrollTo($event,'intro',2)" :class="{active:activeIdx===2}">企业概况</li>
					<li @click="scrollTo($event,'product',4)" :class="{active:activeIdx===3}">核心装备</li>
					<li @click="scrollTo($event,'case',5)" :class="{active:activeIdx===4}">应用案例</li>
					<li @click="scrollTo($event,'partner',6)" :class="{active:activeIdx===5}">合作伙伴</li>
					<li @click="scrollTo($event,'news',7)" :class="{active:activeIdx===6}">新闻资讯</li>
					<li @click="scrollTo($event,'contact',8)" :class="{active:activeIdx===7}">联系我们</li>
				</span>
			</ul>
			<div class="logo">
				<img src="@/assets/logo-img.png">
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'DHeader',
	props:{
		activeIdx:{type:Number,default:1}
	},
	setup(props,{emit}){

		const scrollTo = (e,pix,idx)=>{
			emit('scrollTo',pix,idx);
		}

 		return {scrollTo}
	}
}
</script>

<style scoped>
.d-header{height:116px; box-shadow: 0 1px 3px rgba(0,0,0,.3); position: fixed; top:0; z-index: 210; background: #fff; width:100%;
transition-duration: 300ms;}
.d-header .wrapper{max-width:1000px; margin:auto; height:126px;}
.d-header .wrapper{}

.d-header .wrapper .text-logo{text-align: center;margin: 20px auto 13px; max-width: 800px;}
.d-header .wrapper .text-logo>img{height:38px;}

.nav {position: relative;zoom: 1;text-align: center;}
.nav li{display: inline-block;zoom: 1;padding: 5px 18px; color: #859bb1; font-size: 16px;font-weight: 500; cursor:pointer;}
.nav li:hover,.nav li.active{color: #3167db;}
.logo{display:none; position: absolute;z-index: 101;width: 80px;height: 70px;bottom: -35px;left: 50%;margin-left: -40px;text-align: center; background: url(../../../assets/button-white.png) no-repeat; background-size:100% 100%;}
.logo img{width:42px; margin-top:10px;}

@media screen and (max-width: 800px) {
.d-header{height:90px; }
.d-header .wrapper{max-width:1000px; margin:auto; height:100px;}
.d-header .wrapper .text-logo{text-align: center; margin: 10px auto 12px; max-width: 400px;}
.d-header .wrapper .text-logo>img{height:30px;}

.nav{margin-bottom: 20px;}

.nav li{padding: 5px 6px; font-size:14px;}
.nav li:nth-of-type(1){display:none;}
/*.nav li:nth-of-type(5){display:none;}*/
/*.nav li:nth-of-type(7){display:none;}*/
.logo{width: 70px;height: 60px;bottom: -30px;left: 50%;margin-left: -35px;}
.logo img{width:36px; margin-top:9px;}
}
</style>